<template>
    <div class="page-content">
        <b-row>
            <b-col md="10">
                <page-breadcrumb title="Early Claim" class="mb-3" />
            </b-col>
        </b-row>
        <section class="py-1">
            <early-claim-search-form @getList="getList" @exportData="exportData" />
        </section>
        <h2 class="mb-2">Early Claim Total GGT Report</h2>
        <b-card>
            <early-claim-table
                @getList="getList"
                :items="earlyClaim"
                :is-loading="isLoading"
                :query="query"
                ref="earlyClaimTable"
            />
        </b-card>
        
        <b-pagination
            class="mt-auto"
            pills
            size="lg"
            align="center"
            v-model="current_page"
            :total-rows="total_items"
            :per-page="items_perpage"
        />
        
    </div>
</template>
<script>
import EarlyClaimSearchForm from '../_components/EarlyClaimTotalGGTSearchForm.vue'
import EarlyClaimTable from '../_components/EarlyClaimTotalGGTTable.vue'
import service from "../service"
export default {
    components:{
        EarlyClaimSearchForm,
        EarlyClaimTable
    },
    data(){
        return {
            earlyClaim: [],
            total_items: 0,
            current_page: 1,
            items_perpage: 50,
            isLoading: true,
            export_data: [],
            query: {
                time_range: {
                    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                }
            }
        }
    },
    watch: {
        current_page() {
        this.getList();
        },
    },
    created(){
        this.getList();
    },
    methods: {
        async getList(query = null) {
            if (query){
                this.query = query
            }
            let response = await service.getList({
                page: this.current_page,
                limit: this.items_perpage,
                query: JSON.stringify(this.query)
            });
            if (response.data.type === "DATA") {
                this.earlyClaim = response.data.data.list;
                this.total_items = response.data.data.total;
            }
            this.isLoading = false
        },
        exportData(){
            this.$refs.earlyClaimTable.exportData();
        }
    }

}
</script>