<template>
    <b-table v-if="items" responsive :items="items" :fields="fields" :tbody-tr-class="rowClass">           
        <template #cell(total_earn)="data">
            <span class="click-able"> {{ calTotalCol(data.item) }} </span>
        </template>
    </b-table>       
</template>
<script>
import service from '../service'
import XLSX from "xlsx"
export default {
    props:{
        items: {type: Array, default: null},
        is_loading: {type: Boolean, default: true},
        query: {type: Object, default: null}
    },
    data(){
        return {
            fields: [
                {
                    key: "occur_date",
                    label: "Date",
                    thClass: "table-success",
                    formatter: value => {
                        return this.formatDate(value)
                    },
                },
                {
                    key: "total_referral_earn",
                    label: "Invite Friend (GGT)",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value.toFixed(2) : 0
                    },
                },
                {
                    key: "total_ads_earn",
                    label: "Watch Ads (GGT)",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value.toFixed(2) : 0
                    },
                },
                {
                    key: "total_activity_earn",
                    label: "Learning Activity (GGT)",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value.toFixed(2) : 0
                    },
                },
                {
                    key: "total_earn",
                    label: "Total GGT",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value.toFixed(2) : 0
                    },
                },
                // {
                //     key: "total_ads_earn",
                //     label: "Watch Ads (GGT)",
                //     thClass: "table-success",
                //     formatter: value => {
                //         return value ? value : 0
                //     },
                // },
                {
                    key: "total_referral",
                    label: "Number of user enter Referral Code sucessfully",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },
                {
                    key: "total_referral_enter",
                    label: "Number of entering Referral Code successfully",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },
                {
                    key: "total_invite",
                    label: "Number of users Invite Friend success",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },
                {
                    key: "finish_an_ads",
                    label: "Number of users complete at least 1 Watch Ads mission",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },
                {
                    key: "finish_an_activity",
                    label: "Number of users Complete at least 1 Learning  Mission",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },
                {
                    key: "finish_all_ads",
                    label: "Number of completing Watch Ads Mission",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },
                {
                    key: "finish_all_activity",
                    label: "Number of completing Learning Mission",
                    thClass: "table-success",
                    formatter: value => {
                        return value ? value : 0
                    },
                },

            ],
            current_item: null,
            missions: [],
        }
    },
    watch:{
        items: {
            handler: function () {
                if (this.items.length && this.items[0].occur_date != "Total"){
                    this.items.unshift(
                        {occur_date: "Total", ...this.calTotals(this.items)}
                    )
                }
            },
            deep: true
        }
    },
    created(){
    },
    methods: {
        calTotals(items){
            let total = {
                total_referral_earn: 0,
                total_ads_earn: 0,
                total_activity_earn: 0,
                total_earn: 0,
                total_referral: 0,
                total_referral_enter: 0,
                total_invite: 0, 
                finish_an_ads: 0,
                finish_an_activity: 0,
                finish_all_ads: 0,
                finish_all_activity: 0
            }
            items.forEach((item)=>{
                if (item.occur_date != "Total"){
                    total.total_referral_earn += item.total_referral_earn;
                    total.total_ads_earn += item.total_ads_earn;
                    total.total_activity_earn += item.total_activity_earn;
                    total.total_earn += (item.total_referral_earn + item.total_ads_earn + item.total_activity_earn);
                    total.total_referral += item.total_referral;
                    total.total_referral_enter += item.total_referral_enter;
                    total.total_invite += item.total_invite;
                    total.finish_an_ads += item.finish_an_ads;
                    total.finish_an_activity += item.finish_an_activity;
                    total.finish_all_ads += item.finish_all_ads;
                    total.finish_all_activity += item.finish_all_activity;
                }
                
            })
            return total;
        },
        calTotalCol(data){
            return (data.total_referral_earn + data.total_ads_earn + data.total_activity_earn).toFixed(2)
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.occur_date === 'Total') return 'table-success'
        },
        formatDate(date){
            if (date == "Total"){
                return "Total"
            }
            date = new Date(date);
            date.setDate(date.getDate() + 1)
            return new Date(date).toJSON().slice(0,10).split('-').reverse().join('/')
        },
        async exportData(){
            let response = await service.getAll({query: JSON.stringify(this.query)});
            if (response.data.type === "DATA") {
                this.export_data = response.data.data.list;
            }
            this.export_data = this.export_data.map((result)=>{
                return {
                    occur_date: this.formatDate(result.occur_date),
                    total_referral_earn: result.total_referral_earn || 0,
                    total_ads_earn: result.total_ads_earn || 0,
                    total_activity_earn: result.total_activity_earn || 0,
                    total_earn: result.total_referral_earn + result.total_ads_earn + result.total_activity_earn,
                    total_referral: result.total_referral || 0,
                    total_referral_enter: result.total_referral_enter || 0,
                    total_invite: result.total_invite || 0,
                    finish_an_ads: result.finish_an_ads || 0,
                    finish_an_activity: result.finish_an_activity || 0,
                    finish_all_ads: result.finish_all_ads || 0,
                    finish_all_activity: result.finish_all_activity || 0,
                }
            });
            this.export_data.unshift({occur_date: "Total", ...this.calTotals(this.export_data)})
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(this.export_data, { origin: 'A2', skipHeader: true });
            const header = [[
                'Date',
                'Invite Friend (GGT)',
                'Watch Ads (GGT)',
                'Learning Activity (GGT)',
                'Total (GGT)',
                'Number of user enter Referral Code sucessfully',
                "Number of entering Referral Code successfully",
                'Number of users Invite Friend success',
                'Number of users complete at least 1 Watch Ads mission',
                'Number of users Complete at least 1 Learning  Mission',
                'Number of completing Watch Ads Mission',
                'Number of completing Learning Mission'
                ]];
            XLSX.utils.sheet_add_aoa(ws, header);
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, `early_claim.xlsx`);
            this.export_results = [];
        }
    }
}
</script>
<style scoped>
    .click-able:hover {
        cursor: pointer;
        color: #1198F6
    }
</style>