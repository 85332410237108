import api from '@/libs/axios'
import axios from 'axios'
export default {
	async getList(params) {
		let response = await api.get('/admin/earns/cashflows/early-claim-total-ggt', { params })
		return response
	},
	async getAll(params){
		let response = await api.get('/admin/earns/cashflows/early-claim-total-ggt-all', { params })
		return response
	},
}