<template>
  <div>
    <b-row>
      <b-col md="2">
        <b-form-group label="Market" label-for="market">
          <b-form-select
            v-model="dataForm.language"
            id="market"
			      :options="language_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="5" class="d-flex mr-2">
		<div class="d-flex align-items-center justify-content-center">
			<date-picker
				v-model="dataForm.time_range.start"
				type="date"
				placeholder="Start time"
				:default-value="dataForm.time_range.start"
			/>
			<svg style="width: 2em; height: 2em;"
                class="mx-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
			>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M14 5l7 7m0 0l-7 7m7-7H3"
			/>
			</svg>
			<date-picker
				v-model="dataForm.time_range.end"
				type="date"
				placeholder="End time"
				:default-value="dataForm.time_range.end"
			/>
		</div>
      </b-col>
      <b-col>
        <b-button @click="search" class="mt-0 mt-md-2 mr-1" variant="primary">
          Search
        </b-button>
        <b-button @click="exportData" class="mt-0 mt-md-2 mr-1" variant="success">
          Export
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DatePicker from 'vue2-datepicker';
export default {
  components:{
    DatePicker
  },
  data() {
    return {
      dataForm: {
        time_range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        }
      },
      language_options: [
        {text: "All", value: null},
        {text: "Việt Nam", value: "vi"},
        {text: "Thái", value: "th"},
        {text: "Indo", value: "id"},
        {text: "Eng", value: "en"},
      ],
	  modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
    };
  },
  methods: {
    search() {
      this.$emit("getList", this.dataForm);
    },
    exportData(){
      this.$emit("exportData");
    }
  },
};
</script>
